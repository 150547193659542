import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchSkills = () => useApi(http.get('/content/skills'));

export const fetchCourse = course => useApi(http.get(`/courses/${course}`));

export const fetchCourses = () => useApi(http.get('/courses'));

export const fetchExercises = (course, lesson) =>
  useApi(http.get(`courses/${course}/lessons/${lesson}/exercises`));

export const fetchLesson = (course, lesson) =>
  useApi(http.get(`/courses/${course}/lessons/${lesson}`));

export const updateLesson = (course, lesson, data) =>
  useApi(http.put(`/courses/${course}/lessons/${lesson}`, data));

export const fetchPages = (course: string, lesson: string) => {
  return useApi(http.get(`/courses/${course}/lessons/${lesson}/pages`));
};

export const uploadFile = (
  formData: FormData,
  courseId: any,
  userId: string,
) => {
  return useApi(
    http.post(`/files/user/${userId}/course/${courseId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  );
};

export const listFiles = (
  courseId: string | any,
  userId: string | null = 'null',
) => {
  return useApi(http.get('/files', { params: { courseId, userId } }));
};

export const deleteFile = (fileId: string) => {
  return useApi(http.delete(`/files/${fileId}`));
};
